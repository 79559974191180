/**
 * Created by lilit on 2019-03-08.
 */

import React, { FC, useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Row,
  Column,
  Card,
  CardBody,
  CardActions,
  CardAction,
} from '@nimles/react-web-components';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import {
  CheckboxField,
  TextAreaField,
  InputField,
  RadioField,
} from '@nimles/react-web-forms';
import { ProductGroupWithProductProperties } from '../../../types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUser,
  signInUser,
  createOrder,
  submitOrder,
  createQuote,
  createQuoteRequest,
  createFile,
  submitQuoteRequest,
} from '@nimles/react-redux';
import {
  OrderModel,
  ProductPropertyModel,
  QuoteRequestModel,
  UserModel,
} from '@nimles/models';
import { navigate } from 'gatsby';
import { RootState } from '../../../redux/types';
import { createPassword } from '../../../utils';
import FileField from '../../fields/FileField';
import { FileModel } from '@nimles/models';
import { QuillField } from '../../fields/QuillField';
import { CardArea, CardAreaTitle } from '../FormCard';
import { FieldDescription, FieldSet, FieldTitle } from '../../fields/FieldSet';
import { AddressModel, LocationModel } from '@nimles/models';
import { toLocation } from '../../map/Map';
import { Loader } from '@googlemaps/js-api-loader';
const loader = new Loader({
  apiKey: 'AIzaSyD0JeObfwLX1ZeTp4bOyh0fLbPWM0YXVSw',
  version: 'weekly',
});

interface GeocodeResponse {
  location: LocationModel;
  address: AddressModel;
}

function geocodeAddress({
  street,
  postalCode,
  city,
  country,
}: AddressModel): Promise<GeocodeResponse> {
  return new Promise((resolve, reject) => {
    loader.load().then(() => {
      new google.maps.Geocoder().geocode(
        { address: `${street}, ${postalCode} ${city} ${country}` },
        function (results, status) {
          if (status == 'OK') {
            const addressComponents = results[0]?.address_components;

            const getAddressComponent = (type: string) =>
              addressComponents.find(({ types }) => types.includes(type))
                ?.long_name;

            resolve({
              location: toLocation(results[0]?.geometry?.location?.toJSON()),
              address: {
                postalCode: postalCode ?? getAddressComponent('postal_code'),
                city: city ?? getAddressComponent('locality'),
              },
            });
          } else {
            reject(status);
          }
        }
      );
    });
  });
}

interface Props {
  productGroups?: ProductGroupWithProductProperties[];
  categoryId?: string;
  productGroupId?: string;
}

const ProductPropertyField = ({
  productProperty,
  index,
}: {
  productProperty: ProductPropertyModel;
  index: number;
}) => {
  const { language } = useContext(I18nextContext);
  const { propertyType, options } = productProperty;
  const name =
    (language && productProperty.nameLocalized?.[language]) ||
    productProperty.name;

  switch (propertyType) {
    case 'String':
    case 'SingleLineText':
      return (
        <InputField label={name} name={`productProperties.${index}.value`} />
      );
    case 'MultiLineText':
      return (
        <QuillField label={name} name={`productProperties.${index}.value`} />
      );
    case 'Number':
      return (
        <InputField
          type="number"
          label={name}
          name={`productProperties.${index}.value`}
        />
      );
    case 'MultiOption':
      return (
        <div>
          {options.map(({ name, nameLocalized }, optionIndex) => (
            <CheckboxField
              key={optionIndex}
              label={(language && nameLocalized?.[language]) || name}
              name={`productProperties.${index}.options.${optionIndex}.checked`}
            />
          ))}
        </div>
      );
    case 'Option':
    case 'SingleOption':
      return (
        <div>
          <RadioField
            name={`productProperties.${index}.value`}
            label={name}
            options={options.map(({ name, nameLocalized, value }) => ({
              name: (language && nameLocalized?.[language]) || name,
              value: value,
            }))}
          />
        </div>
      );
    case 'File':
      return (
        <div>
          <FileField
            name={`productProperties.${index}.files`}
            label={name}
            multi
          />
        </div>
      );
    default:
      return <div>{name + propertyType}</div>;
  }
};

interface Props {
  onSubmitted?: (values: any) => void;
  productGroups?: ProductGroupWithProductProperties[];
  categoryId?: string;
  productGroupId?: string;
}

export const QuoteRequestForm: FC<Props> = ({
  onSubmitted,
  productGroups,
  categoryId,
  productGroupId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );
  const { language } = useContext(I18nextContext);

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus }
  ) => {
    try {
      const {
        username,
        firstName,
        lastName,
        street,
        postalCode,
        city,
        categoryId,
        productGroupId,
        description,
      } = values;

      const geocodeResponse = await geocodeAddress({
        postalCode,
        country: 'Spain',
      });

      const productProperties = await Promise.all(
        values.productProperties?.map(async ({ id, value, options, files }) => {
          const fileIds = await Promise.all(
            files.map(
              async (file: FileModel): Promise<string> => {
                if (file.id) {
                  return file.id;
                }

                const fileResponse: any = await dispatch(
                  createFile({ ...file, accessibility: 'PublicExplicit' })
                );

                return fileResponse.id;
              }
            )
          );

          return {
            productPropertyId: id,
            values: fileIds?.length
              ? fileIds
              : value
              ? [value]
              : options
                  ?.filter(({ checked }) => checked)
                  .map(({ value }) => value),
          };
        })
      );

      let userId = user?.id;
      if (!userId) {
        const password = values.password || createPassword();

        const userResponse: any = await dispatch(
          createUser({
            username,
            password,
            firstName,
            lastName,
          })
        );

        userId = userResponse.id;

        await dispatch(signInUser({ username, password }));
      }

      const files: FileModel[] = values.files;

      const fileIds = await Promise.all(
        files.map(
          async (file: FileModel): Promise<string> => {
            if (file.id) {
              return file.id;
            }

            const fileResponse: any = await dispatch(
              createFile({ ...file, accessibility: 'PublicExplicit' })
            );

            return fileResponse.id;
          }
        )
      );

      const quoteRequest: QuoteRequestModel = {
        head: {
          message: values.message,
          buyer: {
            userId,
            firstName,
            lastName,
            email: username,
            deliveryAddress: geocodeResponse.address,
          },
          categoryId,
        },
        lines: [
          {
            categoryId,
            productProperties,
            description,
            productGroupId,
            fileIds,
          },
        ],
      };
      const quoteRequestResponse: any = await dispatch(
        createQuoteRequest(quoteRequest)
      );
      await dispatch(submitQuoteRequest(quoteRequestResponse.id));

      navigate('/account/quotes/' + quoteRequestResponse.id);

      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      console.error(error);
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const productGroup =
    productGroups.find((p) => p.id === productGroupId) || productGroups[0];

  const values = {
    userId: user?.id,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    username: user?.username || '',
    password: user?.password || '',
    categoryId: categoryId || '',
    productGroupId: productGroup?.id || '',
    street: '',
    city: '',
    postalCode: '',
    description: '',
    productProperties:
      productGroup?.productProperties?.map(
        ({
          id,
          options,
          propertyType,
          title,
          titleLocalized,
          description,
          descriptionLocalized,
        }) => ({
          id,
          value: '',
          title,
          titleLocalized,
          description,
          descriptionLocalized,
          propertyType,
          options: options?.map((o) => ({ ...o, checked: false })),
          files: [],
        })
      ) || [],
    files: [],
  };

  return (
    <div>
      <Formik
        initialValues={values}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        enableReinitialize
        render={({ values, handleSubmit, isSubmitting }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              name="simpleForm"
              className="quote-request-form"
            >
              <Card>
                <CardArea>
                  {values.productProperties.map((productProperty, index) => (
                    <FieldSet key={index}>
                      <FieldTitle>
                        {(language &&
                          productProperty.titleLocalized?.[language]) ||
                          productProperty.title}
                      </FieldTitle>
                      <FieldDescription
                        dangerouslySetInnerHTML={{
                          __html:
                            (language &&
                              productProperty.descriptionLocalized?.[
                                language
                              ]) ||
                            productProperty.description,
                        }}
                      />
                      <ProductPropertyField
                        productProperty={productProperty}
                        index={index}
                      />
                    </FieldSet>
                  ))}
                </CardArea>
                <CardArea>
                  <FieldSet>
                    <Row>
                      <Column flex>
                        <InputField
                          required
                          name="firstName"
                          label={t('prop.firstName')}
                          placeholder={t('prop.firstName')}
                          disabled={!!values.userId}
                          value={values.firstName}
                        />
                      </Column>
                      <Column flex>
                        <InputField
                          required
                          name="lastName"
                          label={t('prop.lastName')}
                          placeholder={t('prop.lastName')}
                          disabled={!!values.userId}
                          value={values.lastName}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column flex>
                        <InputField
                          required
                          name="username"
                          label={t('prop.email')}
                          placeholder={t('prop.email')}
                          disabled={!!values.userId}
                          value={values.username}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column flex>
                        <InputField
                          required
                          name="postalCode"
                          label={t('prop.postalCode')}
                          placeholder={t('prop.postalCode')}
                          value={values.postalCode}
                        />
                      </Column>
                    </Row>
                  </FieldSet>
                </CardArea>
                <CardActions>
                  <CardAction
                    primary
                    raised
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t(
                      isSubmitting
                        ? 'action.requestingQuote'
                        : 'action.requestQuote'
                    )}
                  </CardAction>
                </CardActions>
              </Card>
            </Form>
          );
        }}
      />
    </div>
  );
};
