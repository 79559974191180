import React from 'react';
import styled from '@emotion/styled';
import { CategoryModel, OrganizationModel } from '@nimles/models';
import { Section, Container, Row, Column } from '@nimles/react-web-components';
import { ProductGroupWithProductProperties } from '../../../types';
import { QuoteRequestForm } from '../../forms/quoteRequest/QuoteRequestForm';
import { CategoryBanner } from '../banner/CategoryBanner';
import { Text } from '../../Text';

const InformationSection = styled(Section)`
  padding: 30px 0;
  color: #9d9d9d;
  line-height: 1.2;

  h1,
  h2 {
    color: #434345;
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 20px;
  }
`;

interface Props {
  category: CategoryModel;
  productGroups: ProductGroupWithProductProperties[];
  organizations: OrganizationModel[];
  location: Location;
}

export const CategoryPage = ({
  category,
  productGroups,
  location,
  organizations,
}: Props) => {
  return (
    <>
      <CategoryBanner category={category} location={location} />
      <InformationSection>
        <Container>
          <Row wrap="wrap" justify="center">
            <Column xs={100} lg={80} xl={70} justify="center">
              <Text
                center
                dangerouslySetInnerHTML={{ __html: category.description }}
              />
            </Column>
            <Column xs={100} md={50} lg={40} justify="center"></Column>
          </Row>
        </Container>
      </InformationSection>
      <Section>
        <Container>
          <Row wrap="wrap" justify="center">
            <Column xs={100} lg={80} xl={70}>
              <QuoteRequestForm
                categoryId={category.id}
                productGroups={productGroups}
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </>
  );
};
